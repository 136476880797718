import React from 'react';
import { Tr, Td, IconButton } from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

export default function TransactionItem({ transaction, onEdit, onDelete }) {
  const handleDelete = () => {
    fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/delete_transaction`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ document_id: transaction.id })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Delete response:', data);
      if (onDelete) {
        onDelete(transaction.id);
      }
    })
    .catch(error => console.error('Error deleting transaction:', error));
  };
  console.log(transaction.purchased_price);

  return (
    <Tr>
      <Td>{transaction.name}</Td>
      <Td>{transaction.symbol}</Td>
      <Td>{transaction.type}</Td>
      <Td isNumeric>{transaction.coins}</Td>
      <Td isNumeric>${transaction.purchased_price}</Td>
      <Td isNumeric>${transaction.value_usd}</Td>
      <Td>{transaction.status}</Td>
      <Td>{transaction.date}</Td>
      <Td>
        <IconButton
          icon={<EditIcon />}
          colorScheme="blue"
          onClick={() => onEdit()}
          aria-label="Edit"
        />
        <IconButton
          icon={<DeleteIcon />}
          colorScheme="red"
          onClick={handleDelete}
          aria-label="Delete"
        />
      </Td>
    </Tr>
  );
}
