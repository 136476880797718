import React, { useState, useEffect } from "react";
import {
  Center,
  VStack,
  Button,
  ButtonGroup,
  Heading,
  ChakraProvider,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import { Global, css } from '@emotion/react';
import { Link } from "react-router-dom";
import TransactionsTable from "./TransactionsTable";
import AddModal from "./AddModal";
import useCurrentUserDetails from './useCurrentUserId';

function Home() {
  const { userId } = useCurrentUserDetails();
  const [transactions, setTransactions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
 
  useEffect(() => {
    if (userId) {
      fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/get_transactions?userId=${userId}`)
        .then(response => response.json())
        .then(data => setTransactions(data))
        .catch(error => console.error('Error fetching transactions:', error));
    }
  }, [userId]);

  return (
    <ChakraProvider>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
          *, *::before, *::after {
            box-sizing: border-box;
          }
          body {
            margin: 0;
            overflow-x: hidden; // This helps prevent horizontal scrolling
          }
        `}
      />
      <Box bg="gray.800" color="white" minHeight="100vh">
        <AddModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        <Center padding={8} flexDirection="column" justifyContent="center" width="100%">
          <VStack spacing={7} width="100%">
            <Heading>DCrypt0</Heading>
            <ButtonGroup variant="outline" spacing="6">
              <Button size="lg" colorScheme="blue" onClick={onOpen} variant="solid">
                Enter Transaction
              </Button>
              <Link to="/Analysis">
                <Button size="lg" colorScheme="blue" variant="solid">
                  Portfolio Analysis
                </Button>
              </Link>
            </ButtonGroup>
            <TransactionsTable transactions={transactions} setTransactions={setTransactions} />
          </VStack>
        </Center>
      </Box>
    </ChakraProvider>
  );
}

export default Home;
