import React, { useState, useContext } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Heading, useToast, Flex, Text, ChakraProvider, IconButton } from '@chakra-ui/react';
import AuthContext from '../AuthContext'; // Ensure this path matches your project structure
import { Global, css } from '@emotion/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const { userId, email } = useContext(AuthContext);
  const [newEmail, setNewEmail] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page in history
  };
 

  const handleResetPassword = async () => {
    try {
      const response = await fetch('https://crypto-backend-service-4svxr73vvq-uc.a.run.app/resetpassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to reset password.');
      }

      const data = await response.json();
      toast({
        title: 'Success',
        description: data.message || 'Password reset email sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.toString(),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleChangeEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://crypto-backend-service-4svxr73vvq-uc.a.run.app/changeemail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({ uid: userId, new_email: newEmail }),
      });

      if (!response.ok) {
        throw new Error('Failed to change email.');
      }

      const data = await response.json();
      toast({
        title: 'Success',
        description: data.message || 'Email changed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      setNewEmail(''); // Clear the input after successful email change
    } catch (error) {
      toast({
        title: 'Error',
        description: error.toString(),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <ChakraProvider>
      {/* ...Global styles... */}
      <Box bg="gray.800" color="white" minHeight="100vh" p={8}>
        <Flex direction="column" maxW="600px" mx="auto" textAlign="left">
          <IconButton
            aria-label="Back"
            icon={<ArrowBackIcon />}
            size="md"
            colorScheme="blue"
            onClick={handleBack}
            alignSelf="flex-start"
            mb={6}
          />
          <Heading as="h2" size="xl" mb={6}>Account Settings</Heading>
          <Box bg="gray.700" p={6} borderRadius="md">
            <Text fontSize="xl" mb={4}>Current Email: {email}</Text>
            <Button colorScheme="blue" onClick={handleResetPassword} mb={4}>
              Send Password Reset Link
            </Button>
            <form onSubmit={handleChangeEmail}>
              <FormControl id="new-email" isRequired mb={4}>
                <FormLabel>New Email Address:</FormLabel>
                <Input
                  placeholder="Enter new email address"
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </FormControl>
              <Button colorScheme="blue" type="submit" isFullWidth>
                Update Email Address
              </Button>
            </form>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default Settings;