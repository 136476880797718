import React, { useState, useEffect } from 'react';
import { ChakraProvider, VStack, Box, Button, Center, Spinner, Alert, AlertIcon, Text, Flex, useBreakpointValue } from '@chakra-ui/react';
import Summary from '../components/Summary';
import Visualization from '../components/Visualization';
import { useNavigate } from 'react-router-dom';
import useCurrentUserDetails from './useCurrentUserId';
import { Global, css } from '@emotion/react';
import MyAppBar from '../components/appbar';

function App() {
  const { userId, email } = useCurrentUserDetails();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isForecastLoading, setIsForecastLoading] = useState(false); // New loading state for fetching predictions
  const [error, setError] = useState('');
  const [portfolio, setPortfolio] = useState({ cost: 0, value: 0, absoluteGain: 0, totalGainPercent: 0 });
  const [coinsHeld, setCoinsHeld] = useState({ BTC: 0, ETH: 0, SOL: 0 });
  const [rollups, setRollups] = useState([]);
  
  

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      setError('');
      fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/get_details_coinwise?userId=${userId}`, { headers: { 'Cache-Control': 'no-cache' } })
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            setRollups(data);
            let costAccumulator = 0;
            let valueAccumulator = 0;
            let coins = { BTC: 0, ETH: 0, SOL: 0 };

            data.forEach(item => {
              coins[item.symbol] = item.coins;
              costAccumulator += parseFloat(item['total_value'] || 0);
              valueAccumulator += parseFloat(item['total_equity'] || 0);
            });

            setPortfolio({
              cost: costAccumulator,
              value: valueAccumulator,
              absoluteGain: valueAccumulator - costAccumulator,
              totalGainPercent: costAccumulator ? ((valueAccumulator - costAccumulator) / costAccumulator) * 100 : 0,
            });

            setCoinsHeld(coins);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching transactions:', error);
          setError('Failed to fetch transactions');
          setIsLoading(false);
        });
    }
  }, [userId]);

  const navigateToForecast = () => {
    if (userId) {
      setIsForecastLoading(true); // Start loading
      Promise.all([
        fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/predict?symbol=BTC`).then(res => res.json()),
        fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/predict?symbol=ETH`).then(res => res.json()),
        fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/predict?symbol=SOL`).then(res => res.json())
      ]).then(([btcData, ethData, solData]) => {
        const combinedData = {
          BTC: btcData.predictions ? btcData.predictions.map(pred => multiplyValues(pred, coinsHeld.BTC)) : [],
          ETH: ethData.predictions ? ethData.predictions.map(pred => multiplyValues(pred, coinsHeld.ETH)) : [],
          SOL: solData.predictions ? solData.predictions.map(pred => multiplyValues(pred, coinsHeld.SOL)) : []
        };
        
        navigate('/forecast', { state: { forecastData: combinedData, coins: coinsHeld } });
        setIsForecastLoading(false); // End loading
      }).catch(error => {
        console.error("Error fetching forecast data:", error);
        setIsForecastLoading(false); // End loading even on error
        // Handle the error appropriately
      });
    } else {
      console.error("No user ID found");
    }
  };

  const multiplyValues = (prediction, coins) => {
    return {
      Close: prediction.Close * coins,
      High: prediction.High * coins,
      Low: prediction.Low * coins,
      Open: prediction.Open * coins
      // Removed the Volume property since it's not needed
    };
  };
  
  

  const LoadingScreen = () => (
    <Box bg="gray.800" minHeight="100vh" width="full" display="flex" justifyContent="center" alignItems="center">
      <Spinner size="xl" color="white" />
      <Text color="white" ml="4">Loading...</Text>
    </Box>
  );

  if (isLoading || isForecastLoading) {
    return <ChakraProvider><LoadingScreen /></ChakraProvider>;
  }
  

  return (
    <React.StrictMode>
      <ChakraProvider>
      <Global
  styles={css`
    html, body {
      scroll-behavior: smooth;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      overflow-x: hidden; /* Prevent horizontal overflow */
      width: 100%;
      background: gray.800; /* Set background color for the entire content */
      color: white; /* Set text color for the entire content */
      height: 100%; /* Make sure the body height covers the content height */
      min-height: 100vh; /* Ensure the body at least covers the viewport height */
      box-sizing: border-box;
      scroll-behavior: smooth;
    }
    #root, .App { /* Assuming your root div has the id 'root' or class 'App' */
      height: 100%;
      
      display: flex;
      flex-direction: column;
    }
  `}
/>
        <Box bg="gray.800" color="white" minHeight="120vh"  width="full">
       
          {error && (<Alert status="error"><AlertIcon />{error}</Alert>)}
          <VStack>
            <Summary
              portfolioCost={portfolio.cost}
              portfolioValue={portfolio.value}
              absoluteGain={portfolio.absoluteGain}
              totalGainPercent={portfolio.totalGainPercent}
            />
            
            <Visualization rollups={rollups} />
            <Center width="300px" height="100%" justifyContent="space-between">
              <Button size="lg" colorScheme="blue" onClick={() => navigate('/')} variant="solid">Back</Button>
              <Button size="lg" colorScheme="blue" onClick={navigateToForecast} variant="solid">Forecast</Button>
            </Center>
          </VStack>
        </Box>
      </ChakraProvider>
    </React.StrictMode>
  );
}

export default App;
