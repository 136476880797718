import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Button, Select, HStack, Flex, ChakraProvider } from "@chakra-ui/react"; // Import HStack here
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useLocation, useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import * as XLSX from 'xlsx';
import MyAppBar from '../components/appbar';
import { Global, css } from '@emotion/react';

export default function Forecast() {
  const navigate = useNavigate();
  const [forecastData, setForecastData] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState('BTC');
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.forecastData) {
      updateChartData(location.state.forecastData['BTC']);
    }
  }, [location]);

  const updateChartData = (coinData) => {
    if (coinData) {
      const dataWithDates = coinData.map((data, index) => ({
        ...data,
        date: format(addDays(new Date(), index), 'MMM dd, yyyy')
      }));
      setForecastData(dataWithDates);
    } else {
      console.error("Invalid coin data:", coinData);
      setForecastData([]);
    }
  };

  const handleCoinChange = (event) => {
    const coin = event.target.value;
    setSelectedCoin(coin);
    updateChartData(location.state.forecastData[coin]);
  };

  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(forecastData);
    XLSX.utils.book_append_sheet(wb, ws, "ForecastData");
    XLSX.writeFile(wb, "ForecastData.xlsx");
  };

  const goBackToAnalysis = () => {
    navigate('/analysis');
  };

  return (
    <ChakraProvider>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
          *, *::before, *::after {
            box-sizing: border-box;
          }
          body {
            margin: 0;
            overflow-x: hidden;
          }
        `}
      />
    <Box bg="gray.800" color="white" minHeight="100vh" maxWidth="100vw" width="full">
       
      <VStack spacing={8}>
        <Text fontSize="2xl" fontWeight="bold">{selectedCoin} Portfolio Equity Forecast</Text>
        
        <Select onChange={handleCoinChange} value={selectedCoin}>
          <option value="BTC">BTC</option>
          <option value="ETH">ETH</option>
          <option value="SOL">SOL</option>
        </Select>

        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={forecastData}
            margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Close" stroke="#8884d8" />
            <Line type="monotone" dataKey="High" stroke="#82ca9d" />
            <Line type="monotone" dataKey="Low" stroke="#ffc658" />
            <Line type="monotone" dataKey="Open" stroke="#ff7300" />
            
          </LineChart>
        </ResponsiveContainer>

        <HStack spacing={4}> {/* Wrap buttons in HStack */}
        <Button colorScheme="blue" onClick={goBackToAnalysis}>Back</Button>
          <Button colorScheme="blue" onClick={downloadExcel}>Download as Excel</Button>
          
        </HStack>
      </VStack>
    </Box>
    </ChakraProvider>
  );
}
