import React, { useState } from 'react';
import { VStack, Box, Table, Thead, Tbody, Tr, Th, Td, TableCaption, useBreakpointValue } from '@chakra-ui/react';
import TransactionItem from './TransactionItem';
import EditModal from './EditModal';

export default function TransactionsTable({ transactions, setTransactions }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  // Function to open the edit modal with the selected transaction
  const openEditModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsEditModalOpen(true);
  };

  // Function to close the edit modal and clear the selected transaction
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedTransaction(null);
    // Optionally, refresh the transactions list here if needed
  };

  // Function to handle the deletion of a transaction
  const handleDeleteTransaction = (documentId) => {
    // Assuming you have the endpoint setup to delete the transaction
    fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/delete_transaction`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ document_id: documentId }),
    })
    .then(response => response.json())
    .then(data => {
      // Logic to handle response
      setTransactions(currentTransactions => currentTransactions.filter(transaction => transaction.id !== documentId));
    })
    .catch(error => console.error('Error deleting transaction:', error));
  };

  // Determine the table layout based on the breakpoint
  const tableLayout = useBreakpointValue({ base: 'auto', lg: 'fixed' });

  return (
    <VStack width="100%">
      <Box width="100%" overflowX="auto">
        <Table size="sm" variant="striped" colorScheme="blackAlpha" style={{ tableLayout }}>
          <TableCaption placement="top">All crypto buy and sell records</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Symbol</Th>
              <Th>Type</Th>
              <Th isNumeric>Number of Coins</Th>
              <Th isNumeric>Price Purchased At</Th>
              <Th isNumeric>Total Value USD</Th>
              <Th>Status</Th>
              <Th>Transaction Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map(transaction => (
              <TransactionItem
                key={transaction.id}
                transaction={transaction}
                onEdit={() => openEditModal(transaction)}
                onDelete={() => handleDeleteTransaction(transaction.id)}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
      {selectedTransaction && (
        <EditModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          transaction={selectedTransaction}
          setTransactions={setTransactions} // Ensure you pass setTransactions if needed for updating
          documentId={selectedTransaction.id}
        />
      )}
    </VStack>
  );
}
