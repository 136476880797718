import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useCurrentUserDetails = () => {
  // Include email in the initial state for consistency
  const [currentUserDetails, setCurrentUserDetails] = useState({ userId: null, userName: null, email: null });
  
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        // Once a user is authenticated, set their user ID, name, and email
        setCurrentUserDetails({ 
          userId: user.uid,
          userName: user.displayName || 'Anonymous', // Fallback to 'Anonymous' if displayName is not set
          email: user.email,
        });
      } else {
        // Reset all fields if no user is authenticated
        setCurrentUserDetails({ userId: null, userName: null, email: null });
      }
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []);
  
  return currentUserDetails;
};

export default useCurrentUserDetails;
