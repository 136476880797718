import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Center, FormControl, FormLabel, Input, VStack, ChakraProvider, useToast
} from "@chakra-ui/react";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (!email) {
      toast({
        title: "Email Required",
        description: "Please enter your email address.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top", // Ensure the position suits your UI layout
      });
      return; // Early return to stop the function execution
    }

    try {
      const response = await fetch('https://crypto-backend-service-4svxr73vvq-uc.a.run.app/resetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) { // Check if the response status is OK (200-299)
        const data = await response.json(); // Assuming the server returns a JSON response
        toast({
          title: "Password reset email sent.",
          description: "Check your email for the password reset link.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        // Optionally delay navigation to ensure the user sees the toast
        setTimeout(() => navigate('/login'), 3000); // Adjust the delay as needed
      } else {
        const error = await response.json(); // Assuming the server returns a JSON error
        toast({
          title: "An error occurred.",
          description: error.message || "Unable to send password reset email.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      console.error("Reset password error:", error);
      toast({
        title: "Network error",
        description: "Unable to connect to the server.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <ChakraProvider>
      <Center bg="gray.800" minHeight="100vh" minWidth="100vw">
        <VStack spacing={6}>
          <Box w="300px" bg="gray.100" p={8} borderRadius="lg">
            <form onSubmit={handleResetPassword}>
              <VStack spacing={4}>
                <FormControl isRequired id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <Button colorScheme="blue" type="submit">Send Reset Link</Button>
              </VStack>
            </form>
          </Box>
        </VStack>
      </Center>
    </ChakraProvider>
  );
};

export default ForgotPassword;
