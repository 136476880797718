import React from "react";
import { Center, Text, VStack, HStack, useBreakpointValue, Box } from "@chakra-ui/react";
import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Legend } from "recharts";

// Color codes for cryptocurrencies
const BTC_COLOR = "#F7931A"; // Bitcoin: Orange
const ETH_COLOR = "#c99d66"; // Ethereum: Gold-like color
const XRP_COLOR = "#00008b"; // Ripple: Navy Blue
const LTC_COLOR = "#A6A9AA"; // Litecoin: Silver
const BCH_COLOR = "#8DC351"; // Bitcoin Cash: Green
const EOS_COLOR = "#444444"; // EOS: Dark Gray
const XLM_COLOR = "#14B6E7"; // Stellar: Bright Blue
const ADA_COLOR = "#3CC8C8"; // Cardano: Turquoise
const SOL_COLOR = "#4d8bc6"; // Solana: Blue

// Function to get color based on symbol
const getColor = (symbol) => {
  switch (symbol) {
    case "BTC":
      return BTC_COLOR;
    case "ETH":
      return ETH_COLOR;
    case "XRP":
      return XRP_COLOR;
    case "LTC":
      return LTC_COLOR;
    case "BCH":
      return BCH_COLOR;
    case "EOS":
      return EOS_COLOR;
    case "XLM":
      return XLM_COLOR;
    case "ADA":
      return ADA_COLOR;
    case "SOL":
      return SOL_COLOR;
    default:
      return "#000"; // Default color if no match
  }
};

export default function Visualization({ rollups }) {
  const safeRollups = Array.isArray(rollups) ? rollups : [];

  // Use useBreakpointValue to set responsive chart sizes
  const chartWidth = useBreakpointValue({ base: 300, sm: 400, md: 600, lg: 800, xl: 1000 });
  const pieChartSize = useBreakpointValue({ base: 200, sm: 250, md: 300, lg: 350, xl: 400 });

  return (
    <Center padding="4" flexDirection="column">
      <VStack spacing="5">
        <Text fontSize="xl" fontWeight="bold">Cost vs Current Equity</Text>
        <Box overflowX="auto">
          <BarChart
            width={chartWidth}
            height={300}
            data={safeRollups}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="symbol" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="total_equity" name="Total Equity">
              {safeRollups.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getColor(entry.symbol)} />
              ))}
            </Bar>
            <Bar dataKey="total_value" name="Total Cost">
              {safeRollups.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getColor(entry.symbol)} />
              ))}
            </Bar>
          </BarChart>
        </Box>

        <HStack spacing={10} overflowX="auto">
          <Box>
            <Text fontSize="xl" fontWeight="bold">Cost Distribution</Text>
            <PieChart width={pieChartSize} height={pieChartSize}>
              <Pie
              
                data={safeRollups}
                dataKey="total_value"
                nameKey="symbol"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={false}
              >
                {safeRollups.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getColor(entry.symbol)} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </Box>

          <Box>
            <Text fontSize="xl" fontWeight="bold">Equity Distribution</Text>
            <PieChart width={pieChartSize} height={pieChartSize}>
              <Pie
                data={safeRollups}
                dataKey="total_equity"
                nameKey="symbol"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={false}
              >
                {safeRollups.map((entry, index) => (
                  <Cell key={`equity-${index}`} fill={getColor(entry.symbol)} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </Box>
        </HStack>
      </VStack>
    </Center>
  );
}
