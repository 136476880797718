import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Button,
  Input,
  Select,
} from '@chakra-ui/react';

const symbolCoinMapping = {
  "BTC": "bitcoin",
  "ETH": "ethereum",
  "XRP": "ripple",
  "LTC": "litecoin",
  "BCH": "bitcoin-cash",
  "EOS": "eos",
  "XLM": "stellar",
  "ADA": "cardano",
  "SOL": "solana",
};

const EditModal = ({ isOpen, onClose, transaction, onEdit, documentId }) => {
  const [type, setType] = useState('');
  const [symbol, setSymbol] = useState('');
  const [purchasedPrice, setPurchasedPrice] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [coins, setCoins] = useState('');

  useEffect(() => {
    if (transaction) {
      setType(transaction.type || '');
      setSymbol(transaction.symbol || '');
      setPurchasedPrice(transaction.purchased_price?.toString() || ''); // Ensure this is the correct field name
      setDateTime(transaction.date || ''); // Adjust if the field name is different
      setCoins(transaction.coins?.toString() || ''); // Convert to string for input field
    }
  }, [transaction]);

  console.log("pur price is " + transaction.purchased_price);
  
  const editTransaction = () => {
    console.log("Comparing purchased prices:", 
    "Form Value:", purchasedPrice.toString(), 
    "Original Value:", transaction.purchased_price?.toString(),
    "Is Different:", purchasedPrice.toString() !== transaction.purchased_price?.toString()
  );
  
  const payload = {
    // Include all fields that might have been edited
    type: type !== transaction.type ? type : transaction.type,
    symbol: symbol !== transaction.symbol ? symbol : transaction.symbol,
    purchased_price: purchasedPrice.toString() !== transaction.purchased_price?.toString() 
                     ? parseFloat(purchasedPrice) 
                     : transaction.purchased_price,
    date: dateTime !== transaction.date 
          ? new Date(dateTime).toUTCString() 
          : transaction.date,
    coins: coins.toString() !== transaction.coins?.toString() 
           ? parseFloat(coins) 
           : transaction.coins
  };

  // Calculate the updated value_usd based on the possibly new purchased_price and coins
  payload.value_usd = payload.purchased_price * payload.coins;

    fetch(`https://crypto-backend-service-4svxr73vvq-uc.a.run.app/edit_transaction/${documentId}`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(() => {
      onClose(); // Close the modal
      onEdit(); // Callback to refresh the transactions list
    })
    .catch(error => console.error('Error editing transaction:', error));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={8}>
            <Select
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
              placeholder="Select Symbol"
              focusBorderColor="green"
              variant="flushed"
            >
              {Object.keys(symbolCoinMapping).map((key) => (
                <option key={key} value={key}>{key}</option>
              ))}
            </Select>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              placeholder="Select Type"
              focusBorderColor="green"
              variant="flushed"
            >
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </Select>
            <Input
              type="datetime-local"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              placeholder="Transaction Date and Time"
              focusBorderColor="green"
              variant="flushed"
            />
            <Input
              value={purchasedPrice}
              onChange={(e) => setPurchasedPrice(e.target.value)}
              placeholder="Price Purchased At"
              focusBorderColor="green"
              variant="flushed"
            />
            <Input
              value={coins}
              onChange={(e) => setCoins(e.target.value)}
              placeholder="Number of Coins"
              focusBorderColor="green"
              variant="flushed"
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#3182ce"
            color="white"
            mr={3}
            size="lg"
            onClick={editTransaction}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
