// ToastContext.js
import React, { createContext, useContext, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const toast = useToast();

    const showToast = useCallback((options) => {
        toast(options);
    }, [toast]);
    
    return (
        <ToastContext.Provider value={showToast}>
            {children}
        </ToastContext.Provider>
    );
};

export const useToastManager = () => useContext(ToastContext);
