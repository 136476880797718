import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext'; // Adjust the import path as needed
import Home from './components/Home';
import Settings from './components/settings'; // Import your Settings component
import MyAppBar from './components/appbar.js'; // Adjust the import path as needed
import Login from './components/Login';
import Analysis from './components/Analysis';
import Register from './components/register';
import Forecast from './components/Forecast';
import useCurrentUserDetails from './components/useCurrentUserId'; // Adjust the import path as needed
import ForgotPassword from './components/ForgotPassword';

function Layout() {
  const location = useLocation();
  const { userId, userName, email } = useCurrentUserDetails();

  return (
    <>
      {location.pathname !== '/login' && location.pathname!== '/register' && location.pathname!== '/forgot-password' && <MyAppBar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/login" element={<Login />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forecast" element={<Forecast />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* Add other routes as needed */}
      </Routes>
    </>
  );
}

function App() {
  const { userId, userName, email } = useCurrentUserDetails();

  return (
    <AuthContext.Provider value={{ userId, userName, email }}>
      <Router>
        <Layout /> {/* Layout component handles the conditional rendering of MyAppBar */}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;